// |--------------------------------------------------------------------------
// | Transition avec barba et gestion des vues
// |--------------------------------------------------------------------------
// |
// | C'est ici que les transitions entre les pages sont gérées, on y retrouve
// | les différentes vues spécifiques à chaque page. Les vues sont également
// | définies ici.
// |--------------------------------------------------------------------------

import $ from 'jquery'
import barba from '@barba/core'
import Overlay from '../libraries/overlay.js'
import { OBSERVER, SCROLLFIRE } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { adjustButtonsMargins, addSpanToButtons, addSvgToLinks, manageImageLinks, clickToScrollToBlock, changeImg, alerts, separateWords } from './functions/functions.js'
import { fileUpload, select, textareaHeight, formExample, formSuccess, resetDropzone, masksDynamicForm, calendarInput, timepicker, formJobs, formContact, checkboxEnableButton } from './functions/form.js'
import { overlayMenu, overlayShare  } from './functions/overlays.js'
import { hundredVH, isMobile, removeHoverOnMobile, sizeH1, wrapYtVideo } from './functions/helper.js'
import { Accordions } from './functions/accordions.js'
import { slickAbout, slickActivities, slickExpertises, slickHomeBanner, slickRealisations, destroySlick } from './functions/sliders.js'

window.barba = barba
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone

export const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

      // Retire tous les effets d'over sur le mobile
    removeHoverOnMobile()

    isMobile()

    // Permet de retourner en haut de la page
    clickToScrollToBlock({ selector: '[data-js="back-to-top"]' })

    // Les overlays
    overlayMenu()

    // L'alerte
    alerts()

    // Change l'image du menu
    changeImg()
  })
  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
  })
  barba.hooks.enter ((data) => {
    var ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
    var ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
    var ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
    var ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
    document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
    document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
    document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
    document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
  })
  barba.init({

    // Options
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions
    transitions: [
      defaultTransition()
    ],

    // Views
    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          // Scrollfire
          SCROLLFIRE.init()
        },
        afterLeave () {}
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          // Permet d'aller vers le premier élément du contenu de la page
          clickToScrollToBlock({ selector: '[data-js="go-to-sectors"]', scrollTo: '[data-js="sectors"]' })

          hundredVH()  // Permet de donner la hauteur exact en mobile de 100vh
          sizeH1()

          // Les sliders de l'accueil
          slickHomeBanner()
          slickActivities()
          slickExpertises()
          slickRealisations()

          separateWords()

          // Scrollfire
          SCROLLFIRE.init()
        },
        beforeLeave() {
          destroySlick()
        },
        afterLeave() {}
      },
      {
        namespace: 'expertisesView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Overlays
          overlayShare()
        },
        afterLeave() {}
      },
      {
        namespace: 'realisationsView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Overlays
          overlayShare()
        },
        afterLeave() {}
      },
      {
        namespace: 'sectorsView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Rubriques
          new Accordions()

          // Overlays
          overlayShare()

        },
        afterLeave() {}
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Permet d'aller vers le premier élément du contenu de la page
          clickToScrollToBlock({ selector: '[data-js="go-to-jobs"]', scrollTo: '[data-js="page-jobs"]' })

          // Overlays
          overlayShare()
          formJobs()
          fileUpload()
          checkboxEnableButton()
        },
        afterLeave() {}
      },
      {
        namespace: 'jobsDetailView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Permet d'aller vers le premier élément du contenu de la page
          clickToScrollToBlock({ selector: '[data-js="go-to-form"]', scrollTo: '[data-js="page-form"]' })

          // Overlays
          overlayShare()
          formJobs()
          fileUpload()
          checkboxEnableButton()
        },
        afterLeave() {}
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Permet d'aller vers le premier élément du contenu de la page
          clickToScrollToBlock({ selector: '[data-js="go-to-content"]', scrollTo: '[data-js="page-content"]' })

          // Overlays
          overlayShare()

          // Rubriques
          new Accordions()

          // Form
          textareaHeight()
          formContact()
          checkboxEnableButton()
        },
        afterLeave() {}
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Overlays
          overlayShare()

          select()
        },
        afterLeave() {}
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Overlays
          overlayShare()
        },
        afterLeave() {}
      },
      {
        namespace: 'aboutView',
        beforeEnter() {
          // Slider de la page À propos
          slickAbout()

          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Permet d'aller vers le premier élément du contenu de la page
          clickToScrollToBlock({ selector: '[data-js="go-to-content"]', scrollTo: '[data-js="page-content"]' })

          // Overlays
          overlayShare()
        },
        beforeLeave() {
          destroySlick()
        },
        afterLeave() {}
      },
      {
        namespace: 'stylesheetView',
        beforeEnter() {
          separateWords()

          // Scrollfire
          SCROLLFIRE.init()

          // Permet d'aller vers le premier élément du contenu de la page
          clickToScrollToBlock({ selector: '[data-js="go-to-content"]', scrollTo: '[data-js="page-content"]' })

          // Rubriques
          new Accordions()

          // Formulaires
          fileUpload()
          select()
          textareaHeight()
          formExample() //Formulaire de test, devra être changé
          masksDynamicForm()
          timepicker()

          // Boutons et liens
          adjustButtonsMargins()
          addSpanToButtons()
          addSvgToLinks()
          manageImageLinks()

          // Les videos
          wrapYtVideo()

          calendarInput()

          // Overlays
          overlayShare()
        },
        afterLeave() {}
      },
    ]
  })
}

$( document ).ready(function() {
  document.body.style.opacity = '1'
})
