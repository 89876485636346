// |--------------------------------------------------------------------------
// | Fonctions
// |--------------------------------------------------------------------------
// |
// | Les divers scripts du site.
// |--------------------------------------------------------------------------

import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'
import $ from 'jquery'

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })

  return animation.finished
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $('.dynamic p > a.primary-button, .dynamic p > a.secondary-button').each(function() {
    let paragraph = $(this).parent()
    paragraph.addClass('buttonMargin')
  })
  $('.dynamic p.buttonMargin').each(function() {
    if(!($(this).prev().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--first')
    }
    if(!($(this).next().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--last')
    }
  })
}

// Ajouter un <span> dans les boutons pour l'animation
export function addSpanToButtons() {
  let buttons = $('.primary-button, .secondary-button, .js-hover-btn')
  buttons.wrapInner('<span></span>')
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {
  let themePath = window.config.theme_path

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    if (!link.querySelector('img')) {
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-external')
      svgElem.appendChild(useElem)
      link.appendChild(svgElem)
    }
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    if (!link.querySelector('img')) {
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-download')
      svgElem.appendChild(useElem)
      link.appendChild(svgElem)
    }
  })

}

// Ajouter les icones svg pour les boutons et les liens textes
export function manageImageLinks() {

  let links = document.querySelectorAll('.dynamic a')
  links.forEach(function(link) {
    if (link.querySelector('img')) {
      link.classList.add('link-image')
    }
  })

}

export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

// Function permettant de changer l'url de l'image dans le menu
export function changeImg() {
  let pictures = document.querySelectorAll('.overlay-menu__picture')
  let i

  function onHover(e) {
    let image = document.querySelector('.visible-image')
    let imageSrc = JSON.stringify(image.getAttribute('src'))

    if(!e.target.dataset.url) { return }
    else {
      let url = JSON.stringify(e.target.dataset.url)

      if(url == imageSrc) { return }
      else {
        for(i=0; i < pictures.length; i++) {
          let imageSrc = JSON.stringify(pictures[i].getAttribute('data-url'))

          if(imageSrc == url) {
            pictures[i].classList.add('visible-image')
          }
          else { pictures[i].classList.remove('visible-image') }
        }
      }
    }
  }

  OBSERVER.add({
    name: 'image',
    event: 'mouseover',
    target: '.js-change-img',
    function: onHover
  })

  OBSERVER.on('image')
}

// Fonction gérant les alertes
export function alerts() {
  let errorPage = document.querySelector('.js-errors')
  let container = document.querySelector('.js-alert')
  let alert = document.querySelector('.alert')
  let alertMobile = document.querySelector('.alert-mobile')
  let header = document.querySelector('.header')
  let overlayMenu = document.querySelector('.overlay-menu')

  if (typeof (container) != 'undefined' && container != null) {
    if(document.body.contains(errorPage)) {
      alert.style.transition = 'none'
      alertMobile.style.transition = 'none'
      header.style.transition = 'none'
      overlayMenu.style.transition = 'none'

      document.querySelector('html').classList.remove('show-alerts')
    } else {
      const onClick = () => document.querySelector('html').classList.remove('show-alerts')

      OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alert-close' })
      OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alert-mobile-close' })
      OBSERVER.on('alerts')
    }
  } else {
    header.style.transition = 'none'
    overlayMenu.style.transition = 'none'

    document.querySelector('html').classList.remove('show-alerts')
  }

}

// Function séparant les mots
export const separateWords = () => {

  // Avec scrollfire
  const sentencesScrollfire = document.querySelectorAll('.js-words-to-separate-scrollfire')
  let i

  for(i=0; i<sentencesScrollfire.length; i++) {
    let j

    const sentence = sentencesScrollfire[i],
      words = sentence.innerHTML.split(' '),
      wordsLength = words.length

    sentence.innerHTML = ''

    for (j=0; j<wordsLength; j++) {
      //Si le mot est un <br>, ben on met un <br>
      if (words[j] == '<br>') {
        let br = document.createElement('br')
        sentence.append(br)
        continue
      }

      let span1 = document.createElement('span')
      span1.classList.add('span-container')

      let span2 = document.createElement('span')
      span2.classList.add('span-text', 'js-scrollfire')
      span2.appendChild(document.createTextNode(words[j]))

      let spanSpace = document.createElement('span')
      spanSpace.classList.add('span-space', 'js-scrollfire')
      spanSpace.appendChild(document.createTextNode('\xa0'))

      span1.appendChild(span2)
      span1.appendChild(spanSpace)
      sentence.appendChild(span1)
    }

    const titleSentence = document.querySelector('.js-words-to-separate-scrollfire')
    let k

    if(titleSentence.textContent.length > 45) {
      let spanText = document.querySelectorAll('.span-text')

      for(k=0; k<spanText.length; k++) {
        spanText[k].classList.add('smaller')
      }
    }
  }


  // Sans scrollfire
  const sentences = document.querySelectorAll('.js-words-to-separate')
  let l

  for(l=0; l<sentences.length; l++) {
    let m

    const sentence = sentences[l],
      words = sentence.textContent.split(' '),
      wordsLength = words.length

    sentence.innerHTML = ''

    for (m=0; m<wordsLength; m++) {
      let span1 = document.createElement('span')
      span1.classList.add('span-container')

      let span2 = document.createElement('span')
      span2.classList.add('span-text')
      span2.appendChild(document.createTextNode(words[m]))

      let spanSpace = document.createElement('span')
      spanSpace.classList.add('span-space')
      spanSpace.appendChild(document.createTextNode('\xa0'))

      span1.appendChild(span2)
      span1.appendChild(spanSpace)
      sentence.appendChild(span1)
    }
  }
}
